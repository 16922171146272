import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";
import createDecorator from "final-form-calculate";
import { Form, Field } from "react-final-form";
import { DatePicker } from "@material-ui/pickers";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  VisibilityOffRounded,
} from "@material-ui/icons";
import { Scrollbars } from "react-custom-scrollbars";
import {
  ErrorMessage,
  Loading,
  Typography,
  Input,
} from "@web-applications/daffodil-component-library";

import Spacer from "../../../../../newComponents/Spacer";
import Table from "../../../../../newComponents/Table";
import Button from "../../../../../newComponents/Button";
import Divider from "../../../../../newComponents/Divider";
import { GET_PAYMENTS as GET_ALL_ORDER_PAYMENTS } from "../../PaymentsTable";
import { openSnackbar } from "../../../../../components/reusable/Notifier";
import {
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
} from "@material-ui/core";
import NumberFormat from "react-number-format";

const GET_REFUNDABLE_PAYMENTS = gql`
  query getManualRefundablePayments($orderId: ID!) {
    getOrder(orderId: $orderId) {
      _id
      order_status
    }
    getManualRefundablePayments(orderId: $orderId) {
      _id
      time
      payment_type
      amount
    }
  }
`;

const MANUALLY_REFUND_PAYMENTS = gql`
  mutation manuallyRefundPayments(
    $paymentIds: [ID!]
    $newDepositDate: DateTime
    $refundReason: String!
    $customRefundData: CustomRefundDataInput
  ) {
    manuallyRefundPayments(
      paymentIds: $paymentIds
      newDepositDate: $newDepositDate
      refundReason: $refundReason
      customRefundData: $customRefundData
    ) {
      message
      activity {
        _id
        time
        content
        user {
          _id
          first_name
          last_name
        }
      }
      order {
        _id
        to_pay
        order_status
        deposit_date
        completed_date
      }
      payments {
        _id
        payment_number
        time
        timezone
        approval_code
        status
        invoice_number
        amount
        transaction_id
        reference_number
        payment_type
        card_number
        refunded
        cancelled_due_to_restructure
        customer_paid
      }
    }
  }
`;

export default function ManualRefunds({ user, orderId, hideModal, totalPaid }) {
  const [customRefundBool, setCustomRefundBool] = useState(false);

  const toggleCustomRefundBool = () => {
    setCustomRefundBool((prev) => !prev);
  };

  const [manuallyRefundPayments, { loading: manuallyRefundPaymentsLoading }] =
    useMutation(MANUALLY_REFUND_PAYMENTS, {
      onError: ({ message }) => {
        openSnackbar({
          message: message.replace("GraphQL error: ", ""),
          type: "error",
        });
      },
      onCompleted: ({ manuallyRefundPayments: { message } }) => {
        openSnackbar({
          message: message,
          type: "success",
        });
        hideModal();
      },
      update(
        cache,
        {
          data: {
            manuallyRefundPayments: { activity, payments },
          },
        }
      ) {
        // Order is automatically updated in the cache. Activity and payments
        // need upating manually

        // add activity to acitivity list
        const { getActivities } = cache.readQuery({
          query: gql`
            query getActivities($orderId: ID) {
              getActivities(orderId: $orderId) {
                _id
                time
                content
                user {
                  _id
                  first_name
                  last_name
                }
              }
            }
          `,
          variables: {
            orderId,
          },
        });

        cache.writeQuery({
          query: gql`
            query getActivities($orderId: ID) {
              getActivities(orderId: $orderId) {
                _id
                time
                content
                user {
                  _id
                  first_name
                  last_name
                }
              }
            }
          `,
          variables: {
            orderId,
          },
          data: {
            getActivities: [...getActivities, activity],
          },
        });

        cache.writeQuery({
          query: GET_ALL_ORDER_PAYMENTS,
          variables: {
            orderId,
          },
          data: {
            getPayments: payments,
          },
        });
      },
    });

  const {
    loading: paymentsLoading,
    error: paymentsError,
    data: paymentsData,
  } = useQuery(GET_REFUNDABLE_PAYMENTS, {
    variables: { orderId },
    fetchPolicy: "network-only",
  });

  if (paymentsLoading) return <Loading />;

  if (paymentsError) return <ErrorMessage error={paymentsError} />;

  const formattedData = paymentsData.getManualRefundablePayments.map(
    (payment) => ({
      id: payment._id,
      time: moment(payment.time).format("DD/MM/YY, HH:mm:ss"),
      paymentType: payment.payment_type,
      amount: new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(payment.amount),
    })
  );

  //removed in favour of dynamically calculated total paid
  //neccesary now that custom refunds may be added
  // const totalAvailable = paymentsData.getManualRefundablePayments
  //   .map(({ amount }) => parseFloat(amount))
  //   .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  if (!user) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="bodyLarge">
        {customRefundBool
          ? "Add Custom Ammount"
          : "Which payments are being refunded?"}
      </Typography>
      <Spacer height="10px" />

      {user.role_id > 4 && (
        <>
          <VisibilityOffRounded
            style={{ marginBottom: "-10px", marginRight: "10px" }}
          />
          <FormControlLabel
            control={<Switch onChange={toggleCustomRefundBool} />}
            label="Custom Refund"
          />
        </>
      )}
      <Spacer height="10px" />
      <Form
        onSubmit={({
          paymentsToRefund,
          newDepositDate,
          refundReason,
          customRefund,
        }) => {
          const paymentIds = customRefundBool
            ? undefined
            : paymentsToRefund.map(({ id }) => id);
          const customRefundData = customRefundBool
            ? {
                customRefundAmmount: parseFloat(customRefund),
                orderId,
                paymentId: formattedData[0].id,
              }
            : undefined;

          manuallyRefundPayments({
            variables: {
              //if this is a customRefund, dont sendpayment Ids.
              paymentIds,
              newDepositDate,
              refundReason,
              customRefundData, //will be undefined if not submitted.
            },
          });
        }}
        initialValues={{
          customRefund: 0,
          totalToRefund: 0,
          refundingCardDeposit: false,
        }}
        decorators={[
          createDecorator({
            //calculate the total amount selected
            field: "paymentsToRefund",
            updates: async (value) => {
              const totalAmount = value
                .map(({ amount }) =>
                  parseFloat(amount.replace("£", "").replace(",", ""))
                )
                .reduce(
                  (accumulator, currentValue) => accumulator + currentValue,
                  0
                );

              let refundingCardDeposit = false;
              //only check for a new
              if (paymentsData.getOrder.order_status !== "Cancelled") {
                refundingCardDeposit = value.filter(({ paymentType }) =>
                  paymentType.includes("Deposit")
                );

                refundingCardDeposit = refundingCardDeposit.length > 0;
              }

              return {
                totalToRefund: totalAmount,
                refundingCardDeposit,
              };
            },
          }),
        ]}
        validate={({
          refundingCardDeposit,
          newDepositDate,
          refundReason,
          customRefund,
          totalToRefund,
        }) => {
          let errors = {};

          if (customRefundBool && !customRefund) {
            errors.customRefund = "Required";
          }

          if (totalToRefund > totalPaid) {
            errors.refundReason =
              "Sum of payments must be less than total available";
          }

          if (customRefund > totalPaid) {
            errors.customRefund = "Must be less than total available";
          }

          if (refundingCardDeposit && !newDepositDate) {
            errors.newDepositDate = "Required";
          }

          if (!refundReason) {
            errors.refundReason = "Required";
          }

          if (refundReason?.length > 25) {
            errors.refundReason = "Must be less than 25 characters";
          }

          return errors;
        }}
        render={({
          handleSubmit,
          pristine,
          invalid,
          values: { customRefund, totalToRefund, refundingCardDeposit },
        }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Scrollbars autoHeight autoHeightMax={500}>
              {!customRefundBool && (
                <Field name="paymentsToRefund">
                  {({ input: { onChange } }) => (
                    <Table
                      onSelectedRowsChange={onChange}
                      headers={[
                        {
                          Header: "Payment ID",
                          accessor: "id",
                          disableFilters: true,
                          disabledSortBy: true,
                        },
                        {
                          Header: "Time",
                          accessor: "time",
                          disableFilters: true,
                          disabledSortBy: true,
                        },
                        {
                          Header: "Payment Type",
                          accessor: "paymentType",
                          disableFilters: true,
                          disabledSortBy: true,
                        },
                        {
                          Header: "Amount",
                          accessor: "amount",
                          disableFilters: true,
                          disabledSortBy: true,
                        },
                      ]}
                      data={formattedData}
                    />
                  )}
                </Field>
              )}
              <Spacer height="8px" />
              <Typography variant="bodySmall">
                {`Total available to refund: ${new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "GBP",
                }).format(totalPaid)}`}
              </Typography>
              <Spacer height="8px" />
              <Typography variant="bodySmall">
                {`Total to be refunded: ${
                  customRefundBool
                    ? new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      }).format(customRefund)
                    : new Intl.NumberFormat("en-GB", {
                        style: "currency",
                        currency: "GBP",
                      }).format(totalToRefund)
                }`}
              </Typography>
              <Spacer height="32px" />
              {/* the below is acheived though drilling user as a prop, context not available */}
              {customRefundBool && (
                <Field name="customRefund" required>
                  {({ input, meta }) => (
                    <NumberFormat
                      customInput={TextField}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        ),
                      }}
                      {...input}
                      label="Custom Refund Ammount"
                      displayType="tel"
                      decimalScale={2}
                      fixedDecimalScale
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              )}
              <Field
                component={Input}
                name="refundReason"
                fullwidth
                label="Reason For Refund"
                required
              />
              {refundingCardDeposit && (
                <>
                  <Field name="newDepositDate">
                    {({ input, meta }) => (
                      <DatePicker
                        format="DD/MM/YYYY"
                        name={input.name}
                        onChange={input.onChange}
                        value={input.value ? input.value : null}
                        onBlur={input.onBlur}
                        fullWidth
                        required
                        disablePast
                        autoOk
                        label="New Deposit Date"
                        leftArrowIcon={<KeyboardArrowLeft />}
                        inputVariant="outlined"
                        rightArrowIcon={<KeyboardArrowRight />}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                      />
                    )}
                  </Field>
                  <Spacer height="32px" />
                </>
              )}
            </Scrollbars>
            <Divider />
            <Spacer height="32px" />
            <Button
              colour="#8BC34A"
              disabled={pristine || invalid}
              type="submit"
              loading={manuallyRefundPaymentsLoading}
            >
              Refund
            </Button>
          </form>
        )}
      />
    </>
  );
}
