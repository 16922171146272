import React, { useContext } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Form, Field } from "react-final-form";
import gql from "graphql-tag";
import NumberFormat from "react-number-format";
import { UserContext } from "../../util/PageWrapper";
import { openSnackbar } from "./../../reusable/Notifier";
import { openModal, closeModal } from "./../../reusable/Popup";
import Loading from "../../reusable/Loading";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  IconButton,
  TextField,
  Chip,
  CardHeader,
  InputAdornment,
} from "@material-ui/core";

import { Add, Clear, Save } from "@material-ui/icons";
import {
  paymentDenominataionValidation,
  paymentSettingsValidation,
} from "./helpers/paymentSettingsValidation";

const GET_SETTINGS = gql`
  query settings {
    settings {
      _id
      monthly_min_payments
      monthly_max_payments

      weekly_min_payments
      weekly_max_payments

      fortnightly_min_payments
      fortnightly_max_payments

      fourweekly_min_payments
      fourweekly_max_payments

      weekly_payment_amounts
      monthly_payment_amounts
      fortnightly_payment_amounts
      fourweekly_payment_amounts

      payment_attempts
    }
  }
`;

const UPDATE_WEEKLY_PAYMENT_SETTINGS = gql`
  mutation updateWeeklyPaymentSettings(
    $minWeeklyPayments: Int!
    $maxWeeklyPayments: Int!
    $weeklyPaymentDenominations: String!
  ) {
    updateWeeklyPaymentSettings(
      minWeeklyPayments: $minWeeklyPayments
      maxWeeklyPayments: $maxWeeklyPayments
      weeklyPaymentDenominations: $weeklyPaymentDenominations
    ) {
      _id
      weekly_min_payments
      weekly_max_payments
      weekly_payment_amounts
    }
  }
`;

const UPDATE_MONTHLY_PAYMENT_SETTINGS = gql`
  mutation updateMonthlyPaymentSettings(
    $minMonthlyPayments: Int!
    $maxMonthlyPayments: Int!
    $monthlyPaymentDenominations: String!
  ) {
    updateMonthlyPaymentSettings(
      minMonthlyPayments: $minMonthlyPayments
      maxMonthlyPayments: $maxMonthlyPayments
      monthlyPaymentDenominations: $monthlyPaymentDenominations
    ) {
      _id
      monthly_min_payments
      monthly_max_payments
      monthly_payment_amounts
    }
  }
`;

const UPDATE_FORTNIGHTLY_PAYMENT_SETTINGS = gql`
  mutation updateFortnightlyPaymentSettings(
    $minFortnightlyPayments: Int!
    $maxFortnightlyPayments: Int!
    $fortnightlyPaymentDenominations: String!
  ) {
    updateFortnightlyPaymentSettings(
      minFortnightlyPayments: $minFortnightlyPayments
      maxFortnightlyPayments: $maxFortnightlyPayments
      fortnightlyPaymentDenominations: $fortnightlyPaymentDenominations
    ) {
      _id
      fortnightly_min_payments
      fortnightly_max_payments
      fortnightly_payment_amounts
    }
  }
`;

const UPDATE_FOURWEEKLY_PAYMENT_SETTINGS = gql`
  mutation updateFourweeklyPaymentSettings(
    $minFourweeklyPayments: Int!
    $maxFourweeklyPayments: Int!
    $fourweeklyPaymentDenominations: String!
  ) {
    updateFourweeklyPaymentSettings(
      minFourweeklyPayments: $minFourweeklyPayments
      maxFourweeklyPayments: $maxFourweeklyPayments
      fourweeklyPaymentDenominations: $fourweeklyPaymentDenominations
    ) {
      _id
      fourweekly_min_payments
      fourweekly_max_payments
      fourweekly_payment_amounts
    }
  }
`;

const UPDATE_PAYMENT_ATTEMPTS = gql`
  mutation updatePaymentAttemptsSettings($payment_attempts: Int!) {
    updatePaymentAttemptsSettings(payment_attempts: $payment_attempts) {
      _id
      payment_attempts
    }
  }
`;

export default function PaymentSettings() {
  const user = useContext(UserContext);

  const { data: settingsData, loading: settingsLoading } =
    useQuery(GET_SETTINGS);

  let initialValues = { weeklyPaymentDenominations: [] };

  if (settingsData) {
    const { settings } = settingsData;

    //set intiial values

    initialValues = {
      minWeeklyPayments: settings.weekly_min_payments,
      maxWeeklyPayments: settings.weekly_max_payments,
      weeklyPaymentDenominations: settings.weekly_payment_amounts,

      minMonthlyPayments: settings.monthly_min_payments,
      maxMonthlyPayments: settings.monthly_max_payments,
      monthlyPaymentDenominations: settings.monthly_payment_amounts,

      minFortnightlyPayments: settings.fortnightly_min_payments,
      maxFortnightlyPayments: settings.fortnightly_max_payments,
      fortnightlyPaymentDenominations: settings.fortnightly_payment_amounts,

      minFourweeklyPayments: settings.fourweekly_min_payments,
      maxFourweeklyPayments: settings.fourweekly_max_payments,
      fourweeklyPaymentDenominations: settings.fourweekly_payment_amounts,

      paymentAttempts: settings.payment_attempts,
    };
  }

  const formMutators = {
    addPaymentDenomination: (args, state, utils) => {
      const array = args[0];
      //convert to int before resolver is called.
      //prevent floats which break due to settings.graphql.ts declaration as an int!
      //should never be needed becasue of form validation isWholeNumber()
      const newAmount = parseInt(args[1]);

      const existingArray = state.formState.values[array];

      const newArray = [...existingArray, newAmount];

      const sortedArray = newArray.sort((a, b) => a - b);

      utils.changeValue(state, array, () => sortedArray);
    },
    removePaymentDenomination: (args, state, utils) => {
      const index = args[1];
      const array = args[0];

      const existingArray = state.formState.values[array];

      const newArray = existingArray.filter((elem, i) => i !== index);

      const sortedArray = newArray.sort((a, b) => a - b);

      utils.changeValue(state, array, () => sortedArray);
    },
  };

  const [
    updateWeeklyPaymentSettings,
    { loading: updateWeeklyPaymentSettingsLoading },
  ] = useMutation(UPDATE_WEEKLY_PAYMENT_SETTINGS, {
    onError: () => {
      openSnackbar({
        message: "Failed to update weekly payment settings, please try again.",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Weekly payment settings updated",
        type: "success",
      });
    },
  });

  const [
    updateMonthlyPaymentSettings,
    { loading: updateMonthlyPaymentSettingsLoading },
  ] = useMutation(UPDATE_MONTHLY_PAYMENT_SETTINGS, {
    onError: (error) => {
      openSnackbar({
        message:
          error.message ||
          "Failed to update monthly payment settings, please try again.",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Monthly payment settings updated",
        type: "success",
      });
    },
  });

  const [
    updateFortnightlyPaymentSettings,
    { loading: updateFortnightlyPaymentSettingsLoading },
  ] = useMutation(UPDATE_FORTNIGHTLY_PAYMENT_SETTINGS, {
    onError: () => {
      openSnackbar({
        message:
          "Failed to update fortnightly payment settings, please try again.",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Fortnightly payment settings updated",
        type: "success",
      });
    },
  });
  const [
    updateFourweeklyPaymentSettings,
    { loading: updateFourweeklyPaymentSettingsLoading },
  ] = useMutation(UPDATE_FOURWEEKLY_PAYMENT_SETTINGS, {
    onError: () => {
      openSnackbar({
        message:
          "Failed to update 4 weekly payment settings, please try again.",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "4 weekly payment settings updated",
        type: "success",
      });
    },
  });

  const [
    updatePaymentAttemptsSettings,
    { loading: updatePaymentAttemptsLoading },
  ] = useMutation(UPDATE_PAYMENT_ATTEMPTS, {
    onError: () => {
      openSnackbar({
        message:
          "Failed to update payment attempts settings, please try again.",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Payment attempts settings updated",
        type: "success",
      });
    },
  });

  if (settingsLoading) return <Loading />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h4" gutterBottom>
          Payment Settings
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Form
              onSubmit={({
                minWeeklyPayments,
                maxWeeklyPayments,
                weeklyPaymentDenominations,
              }) => {
                updateWeeklyPaymentSettings({
                  variables: {
                    minWeeklyPayments: parseInt(minWeeklyPayments),
                    maxWeeklyPayments: parseInt(maxWeeklyPayments),
                    weeklyPaymentDenominations: JSON.stringify(
                      weeklyPaymentDenominations
                    ),
                  },
                });
              }}
              validate={({
                minWeeklyPayments,
                maxWeeklyPayments,
                weeklyPaymentDenominations,
              }) => {
                const errors = paymentSettingsValidation(
                  "Weekly",
                  minWeeklyPayments,
                  maxWeeklyPayments,
                  weeklyPaymentDenominations
                );

                return errors;
              }}
              mutators={{ ...formMutators }}
              initialValues={initialValues}
              render={({
                handleSubmit,
                invalid,
                values,
                errors,
                form: {
                  mutators: {
                    addPaymentDenomination,
                    removePaymentDenomination,
                  },
                },
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="h6" gutterBottom>
                        Weekly Payment Schedule
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name="minWeeklyPayments">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Min Payments"
                            fullWidth
                            required
                            variant="outlined"
                            type="number"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name="maxWeeklyPayments">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Max Payments"
                            fullWidth
                            required
                            variant="outlined"
                            type="number"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography component="h3" variant="h6">
                          Payment Denomination Options
                        </Typography>
                        <IconButton
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            openModal({
                              content: (
                                <Card>
                                  <CardHeader
                                    action={
                                      <IconButton
                                        aria-label="Close Pop-up"
                                        onClick={closeModal}
                                      >
                                        <Clear />
                                      </IconButton>
                                    }
                                    title="Add Weekly Payment Denomination"
                                  />
                                  <CardContent>
                                    <Form
                                      onSubmit={({ amount }) => {
                                        addPaymentDenomination(
                                          "weeklyPaymentDenominations",
                                          amount
                                        );
                                        closeModal();
                                      }}
                                      validate={({ amount }) => {
                                        const errors =
                                          paymentDenominataionValidation(
                                            amount,
                                            values.weeklyPaymentDenominations
                                          );

                                        return errors;
                                      }}
                                      render={({
                                        handleSubmit: addAmountSubmit,
                                        pristine: addAmountPristine,
                                        invalid: addAmountInvalid,
                                      }) => (
                                        <form
                                          onSubmit={addAmountSubmit}
                                          autoComplete="off"
                                          noValidate
                                        >
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} md={3}>
                                              <Field name="amount">
                                                {({ input, meta }) => (
                                                  <NumberFormat
                                                    customInput={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          £
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    required
                                                    error={
                                                      meta.error && meta.touched
                                                    }
                                                    helperText={
                                                      meta.error && meta.touched
                                                        ? meta.error
                                                        : ""
                                                    }
                                                    {...input}
                                                    label="Amount"
                                                    displayType="tel"
                                                    fixedDecimalScale
                                                  />
                                                )}
                                              </Field>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={
                                                  addAmountPristine ||
                                                  addAmountInvalid
                                                }
                                              >
                                                Add Amount
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </form>
                                      )}
                                    />
                                  </CardContent>
                                </Card>
                              ),
                            });
                          }}
                        >
                          <Add />
                        </IconButton>
                      </div>

                      {values.weeklyPaymentDenominations.map(
                        (weeklyPaymentAmount, index) => (
                          <Chip
                            label={`£${weeklyPaymentAmount}`}
                            key={weeklyPaymentAmount}
                            style={{
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                            onDelete={() => {
                              removePaymentDenomination(
                                "weeklyPaymentDenominations",
                                index
                              );
                            }}
                          />
                        )
                      )}
                      {!!errors.weeklyPaymentDenominations && (
                        <Typography
                          component="p"
                          variant="body1"
                          style={{ color: "red" }}
                        >
                          {errors.weeklyPaymentDenominations}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={updateWeeklyPaymentSettingsLoading || invalid}
                      >
                        <Save style={{ marginRight: "10px" }} />
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Form
              onSubmit={({
                minMonthlyPayments,
                maxMonthlyPayments,
                monthlyPaymentDenominations,
              }) => {
                updateMonthlyPaymentSettings({
                  variables: {
                    minMonthlyPayments: parseInt(minMonthlyPayments),
                    maxMonthlyPayments: parseInt(maxMonthlyPayments),
                    monthlyPaymentDenominations: JSON.stringify(
                      monthlyPaymentDenominations
                    ),
                  },
                });
              }}
              validate={({
                minMonthlyPayments,
                maxMonthlyPayments,
                monthlyPaymentDenominations,
              }) => {
                const errors = paymentSettingsValidation(
                  "Monthly",
                  minMonthlyPayments,
                  maxMonthlyPayments,
                  monthlyPaymentDenominations
                );

                return errors;
              }}
              mutators={{ ...formMutators }}
              initialValues={initialValues}
              render={({
                handleSubmit,
                invalid,
                values,
                errors,
                form: {
                  mutators: {
                    addPaymentDenomination,
                    removePaymentDenomination,
                  },
                },
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="h6" gutterBottom>
                        Monthly Payment Schedule
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name="minMonthlyPayments">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Min Payments"
                            fullWidth
                            required
                            variant="outlined"
                            type="number"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name="maxMonthlyPayments">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Max Payments"
                            fullWidth
                            required
                            variant="outlined"
                            type="number"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography component="h3" variant="h6">
                          Payment Denomination Options
                        </Typography>
                        <IconButton
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            openModal({
                              content: (
                                <Card>
                                  <CardHeader
                                    action={
                                      <IconButton
                                        aria-label="Close Pop-up"
                                        onClick={closeModal}
                                      >
                                        <Clear />
                                      </IconButton>
                                    }
                                    title="Add Monthly Payment Denomination"
                                  />
                                  <CardContent>
                                    <Form
                                      onSubmit={({ amount }) => {
                                        addPaymentDenomination(
                                          "monthlyPaymentDenominations",
                                          amount
                                        );
                                        closeModal();
                                      }}
                                      validate={({ amount }) => {
                                        const errors =
                                          paymentDenominataionValidation(
                                            amount,
                                            values.monthlyPaymentDenominations
                                          );

                                        return errors;
                                      }}
                                      render={({
                                        handleSubmit: addAmountSubmit,
                                        pristine: addAmountPristine,
                                        invalid: addAmountInvalid,
                                      }) => (
                                        <form
                                          onSubmit={addAmountSubmit}
                                          autoComplete="off"
                                          noValidate
                                        >
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} md={3}>
                                              <Field name="amount">
                                                {({ input, meta }) => (
                                                  <NumberFormat
                                                    customInput={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          £
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    required
                                                    error={
                                                      meta.error && meta.touched
                                                    }
                                                    helperText={
                                                      meta.error && meta.touched
                                                        ? meta.error
                                                        : ""
                                                    }
                                                    {...input}
                                                    label="Amount"
                                                    displayType="tel"
                                                    fixedDecimalScale
                                                  />
                                                )}
                                              </Field>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={
                                                  addAmountPristine ||
                                                  addAmountInvalid
                                                }
                                              >
                                                Add Amount
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </form>
                                      )}
                                    />
                                  </CardContent>
                                </Card>
                              ),
                            });
                          }}
                        >
                          <Add />
                        </IconButton>
                      </div>

                      {values.monthlyPaymentDenominations.map(
                        (monthlyPaymentAmount, index) => (
                          <Chip
                            label={`£${monthlyPaymentAmount}`}
                            key={monthlyPaymentAmount}
                            style={{
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                            onDelete={() => {
                              removePaymentDenomination(
                                "monthlyPaymentDenominations",
                                index
                              );
                            }}
                          />
                        )
                      )}
                      {!!errors.monthlyPaymentDenominations && (
                        <Typography
                          component="p"
                          variant="body1"
                          style={{ color: "red" }}
                        >
                          {errors.monthlyPaymentDenominations}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                          updateMonthlyPaymentSettingsLoading || invalid
                        }
                      >
                        <Save style={{ marginRight: "10px" }} />
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Form
              onSubmit={({
                minFortnightlyPayments,
                maxFortnightlyPayments,
                fortnightlyPaymentDenominations,
              }) => {
                updateFortnightlyPaymentSettings({
                  variables: {
                    minFortnightlyPayments: parseInt(minFortnightlyPayments),
                    maxFortnightlyPayments: parseInt(maxFortnightlyPayments),
                    fortnightlyPaymentDenominations: JSON.stringify(
                      fortnightlyPaymentDenominations
                    ),
                  },
                });
              }}
              validate={({
                minFortnightlyPayments,
                maxFortnightlyPayments,
                fortnightlyPaymentDenominations,
              }) => {
                const errors = paymentSettingsValidation(
                  "Fortnightly",
                  minFortnightlyPayments,
                  maxFortnightlyPayments,
                  fortnightlyPaymentDenominations
                );

                return errors;
              }}
              mutators={{ ...formMutators }}
              initialValues={initialValues}
              render={({
                handleSubmit,
                invalid,
                values,
                errors,
                form: {
                  mutators: {
                    addPaymentDenomination,
                    removePaymentDenomination,
                  },
                },
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="h6" gutterBottom>
                        Fortnightly Payment Schedule
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name="minFortnightlyPayments">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Min Payments"
                            fullWidth
                            required
                            variant="outlined"
                            type="number"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name="maxFortnightlyPayments">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Max Payments"
                            fullWidth
                            required
                            variant="outlined"
                            type="number"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography component="h3" variant="h6">
                          Payment Denomination Options
                        </Typography>
                        <IconButton
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            openModal({
                              content: (
                                <Card>
                                  <CardHeader
                                    action={
                                      <IconButton
                                        aria-label="Close Pop-up"
                                        onClick={closeModal}
                                      >
                                        <Clear />
                                      </IconButton>
                                    }
                                    title="Add Fortnightly Payment Denomination"
                                  />
                                  <CardContent>
                                    <Form
                                      onSubmit={({ amount }) => {
                                        addPaymentDenomination(
                                          "fortnightlyPaymentDenominations",
                                          amount
                                        );
                                        closeModal();
                                      }}
                                      validate={({ amount }) => {
                                        const errors =
                                          paymentDenominataionValidation(
                                            amount,
                                            values.fortnightlyPaymentDenominations
                                          );

                                        return errors;
                                      }}
                                      render={({
                                        handleSubmit: addAmountSubmit,
                                        pristine: addAmountPristine,
                                        invalid: addAmountInvalid,
                                      }) => (
                                        <form
                                          onSubmit={addAmountSubmit}
                                          autoComplete="off"
                                          noValidate
                                        >
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} md={3}>
                                              <Field name="amount">
                                                {({ input, meta }) => (
                                                  <NumberFormat
                                                    customInput={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          £
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    required
                                                    error={
                                                      meta.error && meta.touched
                                                    }
                                                    helperText={
                                                      meta.error && meta.touched
                                                        ? meta.error
                                                        : ""
                                                    }
                                                    {...input}
                                                    label="Amount"
                                                    displayType="tel"
                                                    fixedDecimalScale
                                                  />
                                                )}
                                              </Field>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={
                                                  addAmountPristine ||
                                                  addAmountInvalid
                                                }
                                              >
                                                Add Amount
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </form>
                                      )}
                                    />
                                  </CardContent>
                                </Card>
                              ),
                            });
                          }}
                        >
                          <Add />
                        </IconButton>
                      </div>

                      {values.fortnightlyPaymentDenominations.map(
                        (monthlyPaymentAmount, index) => (
                          <Chip
                            label={`£${monthlyPaymentAmount}`}
                            key={monthlyPaymentAmount}
                            style={{
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                            onDelete={() => {
                              removePaymentDenomination(
                                "fortnightlyPaymentDenominations",
                                index
                              );
                            }}
                          />
                        )
                      )}
                      {!!errors.fortnightlyPaymentDenominations && (
                        <Typography
                          component="p"
                          variant="body1"
                          style={{ color: "red" }}
                        >
                          {errors.fortnightlyPaymentDenominations}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                          updateFortnightlyPaymentSettingsLoading || invalid
                        }
                      >
                        <Save style={{ marginRight: "10px" }} />
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Form
              onSubmit={({
                minFourweeklyPayments,
                maxFourweeklyPayments,
                fourweeklyPaymentDenominations,
              }) => {
                updateFourweeklyPaymentSettings({
                  variables: {
                    minFourweeklyPayments: parseInt(minFourweeklyPayments),
                    maxFourweeklyPayments: parseInt(maxFourweeklyPayments),
                    fourweeklyPaymentDenominations: JSON.stringify(
                      fourweeklyPaymentDenominations
                    ),
                  },
                });
              }}
              validate={({
                minFourweeklyPayments,
                maxFourweeklyPayments,
                fourweeklyPaymentDenominations,
              }) => {
                const errors = paymentSettingsValidation(
                  "Fourweekly",
                  minFourweeklyPayments,
                  maxFourweeklyPayments,
                  fourweeklyPaymentDenominations
                );

                return errors;
              }}
              mutators={{ ...formMutators }}
              initialValues={initialValues}
              render={({
                handleSubmit,
                invalid,
                values,
                errors,
                form: {
                  mutators: {
                    addPaymentDenomination,
                    removePaymentDenomination,
                  },
                },
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="h6" gutterBottom>
                        4 Weekly Payment Schedule
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name="minFourweeklyPayments">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Min Payments"
                            fullWidth
                            required
                            variant="outlined"
                            type="number"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Field name="maxFourweeklyPayments">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Max Payments"
                            fullWidth
                            required
                            variant="outlined"
                            type="number"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography component="h3" variant="h6">
                          Payment Denomination Options
                        </Typography>
                        <IconButton
                          style={{ marginLeft: "10px" }}
                          onClick={() => {
                            openModal({
                              content: (
                                <Card>
                                  <CardHeader
                                    action={
                                      <IconButton
                                        aria-label="Close Pop-up"
                                        onClick={closeModal}
                                      >
                                        <Clear />
                                      </IconButton>
                                    }
                                    title="Add 4 Weekly Payment Denomination"
                                  />
                                  <CardContent>
                                    <Form
                                      onSubmit={({ amount }) => {
                                        addPaymentDenomination(
                                          "fourweeklyPaymentDenominations",
                                          amount
                                        );
                                        closeModal();
                                      }}
                                      validate={({ amount }) => {
                                        const errors =
                                          paymentDenominataionValidation(
                                            amount,
                                            values.fourweeklyPaymentDenominations
                                          );

                                        return errors;
                                      }}
                                      render={({
                                        handleSubmit: addAmountSubmit,
                                        pristine: addAmountPristine,
                                        invalid: addAmountInvalid,
                                      }) => (
                                        <form
                                          onSubmit={addAmountSubmit}
                                          autoComplete="off"
                                          noValidate
                                        >
                                          <Grid container spacing={3}>
                                            <Grid item xs={12} md={3}>
                                              <Field name="amount">
                                                {({ input, meta }) => (
                                                  <NumberFormat
                                                    customInput={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          £
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    required
                                                    error={
                                                      meta.error && meta.touched
                                                    }
                                                    helperText={
                                                      meta.error && meta.touched
                                                        ? meta.error
                                                        : ""
                                                    }
                                                    {...input}
                                                    label="Amount"
                                                    displayType="tel"
                                                    fixedDecimalScale
                                                  />
                                                )}
                                              </Field>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={
                                                  addAmountPristine ||
                                                  addAmountInvalid
                                                }
                                              >
                                                Add Amount
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </form>
                                      )}
                                    />
                                  </CardContent>
                                </Card>
                              ),
                            });
                          }}
                        >
                          <Add />
                        </IconButton>
                      </div>

                      {values.fourweeklyPaymentDenominations.map(
                        (monthlyPaymentAmount, index) => (
                          <Chip
                            label={`£${monthlyPaymentAmount}`}
                            key={monthlyPaymentAmount}
                            style={{
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                            onDelete={() => {
                              removePaymentDenomination(
                                "fourweeklyPaymentDenominations",
                                index
                              );
                            }}
                          />
                        )
                      )}
                      {!!errors.fourweeklyPaymentDenominations && (
                        <Typography
                          component="p"
                          variant="body1"
                          style={{ color: "red" }}
                        >
                          {errors.fourweeklyPaymentDenominations}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                          updateFourweeklyPaymentSettingsLoading || invalid
                        }
                      >
                        <Save style={{ marginRight: "10px" }} />
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </CardContent>
        </Card>
      </Grid>

      {user.role_id > 4 && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Form
                onSubmit={({ paymentAttempts }) => {
                  updatePaymentAttemptsSettings({
                    variables: {
                      payment_attempts: parseInt(paymentAttempts),
                    },
                  });
                }}
                initialValues={initialValues}
                render={({ handleSubmit, invalid, values, errors, form }) => (
                  <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography component="h2" variant="h6" gutterBottom>
                          Payment Attempts
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Field name="paymentAttempts">
                          {({ input, meta }) => (
                            <TextField
                              {...input}
                              label="Payment attempts"
                              fullWidth
                              required
                              variant="outlined"
                              type="number"
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : ""
                              }
                            />
                          )}
                        </Field>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={updatePaymentAttemptsLoading}
                        >
                          <Save style={{ marginRight: "10px" }} />
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
