import React, { useState, Fragment, useContext } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import { openSnackbar } from "./../../reusable/Notifier";
import SupportButton from "../../reusable/SupportButton";
import PruneOrders from "./PruneOrders";

import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Popover,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import {
  FilterList,
  Search,
  CloudDownload,
  ChevronRight,
  KeyboardArrowDown,
} from "@material-ui/icons";

import OrdersTable from "./OrdersTable";
import Loading from "./../../reusable/Loading";

import SearchIcon from "./../../../assets/images/Search.svg";

import { UserContext } from "./../../util/PageWrapper";

const GET_NUMBER_OF_ORDERS = gql`
  query numberOfOrders(
    $agent: String
    $stores: String
    $liveOrders: Boolean
    $completedOrders: Boolean
    $cancelledOrders: Boolean
    $orderNumber: String
    $firstName: String
    $lastName: String
    $postcode: String
    $advancedSearch: String
  ) {
    numberOfOrders(
      agent: $agent
      stores: $stores
      liveOrders: $liveOrders
      completedOrders: $completedOrders
      cancelledOrders: $cancelledOrders
      orderNumber: $orderNumber
      firstName: $firstName
      lastName: $lastName
      postcode: $postcode
      advancedSearch: $advancedSearch
    )
  }
`;
const GET_ORDERS = gql`
  query orders(
    # removed to allow all users to see all orders.
    # $agent: String
    # $stores: String
    $liveOrders: Boolean
    $completedOrders: Boolean
    $cancelledOrders: Boolean
    $orderNumber: String
    $firstName: String
    $lastName: String
    $postcode: String
    $advancedSearch: String
  ) {
    orders(
      # agent: $agent
      # stores: $stores
      liveOrders: $liveOrders
      completedOrders: $completedOrders
      cancelledOrders: $cancelledOrders
      orderNumber: $orderNumber
      firstName: $firstName
      lastName: $lastName
      postcode: $postcode
      advancedSearch: $advancedSearch
    ) {
      _id
      invoice_number
      agent {
        _id
        first_name
        last_name
      }
      store {
        _id
        name
      }
      order_status
      created_date
      customer {
        _id
        first_name
        last_name
        title
        customer_type
      }
      release_date
      total_retail
      to_pay
      grand_total
      deposit_date
      weekly_payment
      deposit
      deposit_paid
    }
  }
`;

const DOWNLOAD_ALL_ORDER_INFORMATION = gql`
  query downloadAllOrderInformation {
    downloadAllOrderInformation
  }
`;

const DOWNLOAD_ALL_DISCOUNT_INFORMATION = gql`
  query downloadAllDiscountInformation {
    downloadAllDiscountInformation
  }
`;

export default function OrdersSearch() {
  const [numberOfOrders, setNumberOfOrders] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [liveOrders, setLiveOrders] = useState(true);
  const [orders, setOrders] = useState(null);
  const [completedOrders, setCompletedOrders] = useState(true);
  const [cancelledOrders, setCancelledOrders] = useState(true);
  const [orderNumber, setOrderNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  const [advancedSearch, setAdvancedSearch] = useState("");
  const [advancedSearchVisability, setAdvancedSearchVisability] =
    useState(false);

  const user = useContext(UserContext);

  let variables = {
    liveOrders: liveOrders,
    completedOrders: completedOrders,
    cancelledOrders: cancelledOrders,
    orderNumber: orderNumber,
    firstName: firstName,
    lastName: lastName,
    postcode: postcode,
    advancedSearch: advancedSearch,
  };
  //all users now see all orders
  //Expected PPW will want a change in the future JK
  // switch (user.role_id) {
  // removed so agents can see all orders
  // case 1:
  //   // Agent

  //   variables.agent = user._id;

  //   break;

  // case 2:
  // Store owner

  // variables.stores = JSON.stringify(user.stores);

  // break;

  // default:
  // Admin

  // break;
  // }

  //Mutation/Query Hooks
  const [getOrders, { loading: ordersLoading, error: loadingOrdersError }] =
    useLazyQuery(GET_ORDERS, {
      onCompleted: ({ orders: returnedOrders }) => {
        setFirstLoad(false);
        setNumberOfOrders(returnedOrders.length);
        setOrders(returnedOrders);
      },
    });

  const [
    getNumberOfOrders,
    { loading: numberOfOrdersLoading, error: numberOfOrdersError },
  ] = useLazyQuery(GET_NUMBER_OF_ORDERS, {
    onCompleted: ({ numberOfOrders }) => {
      setFirstLoad(false);
      if (numberOfOrders <= 10 && numberOfOrders > 0) {
        getOrders({ variables: variables });
      } else {
        setOrders(null);
      }

      setNumberOfOrders(numberOfOrders);
    },
  });

  const [
    downloadAllOrderInformation,
    { loading: downloadAllOrderInformationLoading },
  ] = useLazyQuery(DOWNLOAD_ALL_ORDER_INFORMATION, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar({
        message: "Failed to get download, please try again.",
        type: "error",
      });
    },
    onCompleted: ({ downloadAllOrderInformation }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${downloadAllOrderInformation}`;
    },
  });

  const [
    downloadAllDiscountInformation,
    { loading: downloadAllDiscountInformationLoading },
  ] = useLazyQuery(DOWNLOAD_ALL_DISCOUNT_INFORMATION, {
    fetchPolicy: "network-only",
    onError: () => {
      openSnackbar({
        message: "Failed to get download, please try again.",
        type: "error",
      });
    },
    onCompleted: ({ downloadAllDiscountInformation }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${downloadAllDiscountInformation}`;
    },
  });

  let view = (
    <Fragment>
      <Typography variant="body1" style={{ marginBottom: "5px" }}>
        No results found
      </Typography>
      <Typography variant="body1" style={{ marginBottom: "5px" }}>
        Search again to see results
      </Typography>
    </Fragment>
  );

  if (loadingOrdersError || numberOfOrdersError) {
    view = (
      <Fragment>
        <Typography variant="body1" style={{ marginBottom: "5px" }}>
          Error
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px" }}>
          There was an error fetching orders, please try again.
        </Typography>
      </Fragment>
    );
  } else if (ordersLoading || numberOfOrdersLoading) {
    view = <Loading />;
  } else if (firstLoad) {
    view = (
      <Fragment>
        <div
          style={{
            backgroundImage: `url(${SearchIcon})`,
            height: "80px",
            width: "62px",
            marginBottom: "30px",
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <Typography variant="body1" style={{ marginBottom: "5px" }}>
          Your search results will appear here
        </Typography>
      </Fragment>
    );
  } else if (orders) {
    view = <OrdersTable orders={orders} />;
  } else if (numberOfOrders) {
    view = (
      <Fragment>
        <Typography
          variant="h1"
          style={{ marginBottom: "5px", fontWeight: "bold" }}
        >
          {numberOfOrders}
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px" }}>
          results for your search criteria
        </Typography>
        <Typography variant="body1" style={{ marginBottom: "5px" }}>
          Refine search to see results
        </Typography>
        <Typography
          variant="caption"
          style={{
            fontStyle: "italic",
            marginBottom: "5px",
            textTransform: "lowercase",
          }}
        >
          or
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            getOrders({ variables: variables });
          }}
        >
          Show Results Now
        </Button>
      </Fragment>
    );
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        getNumberOfOrders({ variables: variables });
      }}
    >
      <Card>
        <CardHeader
          style={{ paddingTop: "0", paddingBottom: "0" }}
          title={
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <Typography variant="h6">Orders</Typography>
              <Typography
                variant="h6"
                style={{ color: "grey", marginLeft: "10px" }}
              >
                {numberOfOrders && `(${numberOfOrders})`}
              </Typography>
            </div>
          }
          action={
            <Fragment>
              {user.role_id >= 3 && (
                <Fragment>
                  <SupportButton text={"Prune"}>
                    <PruneOrders />
                  </SupportButton>
                  <Button
                    style={{ marginLeft: "20px" }}
                    disabled={downloadAllDiscountInformationLoading}
                    onClick={() => {
                      openSnackbar({
                        message: "Your download is being created.",
                        type: "success",
                      });

                      downloadAllDiscountInformation();
                    }}
                  >
                    <CloudDownload style={{ marginRight: "10px" }} />
                    {downloadAllDiscountInformationLoading
                      ? "Loading"
                      : "Download All Increases/Decreases"}
                  </Button>
                  <Button
                    style={{ marginLeft: "20px" }}
                    disabled={downloadAllOrderInformationLoading}
                    onClick={() => {
                      openSnackbar({
                        message: "Your download is being created.",
                        type: "success",
                      });

                      downloadAllOrderInformation();
                    }}
                  >
                    <CloudDownload style={{ marginRight: "10px" }} />
                    {downloadAllOrderInformationLoading
                      ? "Loading..."
                      : "Download All Orders"}
                  </Button>
                </Fragment>
              )}
              <Tooltip title="Filter" placement="bottom">
                <IconButton
                  onClick={(event) => {
                    setFilterOpen(true);
                    setAnchorEl(event.currentTarget);
                  }}
                  id="filter-button"
                >
                  <FilterList color={filterOpen ? "primary" : "inherit"} />
                </IconButton>
              </Tooltip>
              <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={filterOpen}
                onClose={() => {
                  setFilterOpen(false);
                  setAnchorEl(null);
                }}
              >
                <div style={{ padding: "20px", minWidth: "300px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="button">Filter</Typography>
                    <Button
                      color="primary"
                      onClick={() => {
                        // reset filters
                        setLiveOrders(true);
                        setCompletedOrders(true);
                        setCancelledOrders(true);

                        // rerun query
                        variables.liveOrders = true;
                        variables.completedOrders = true;
                        variables.cancelledOrders = true;

                        //if its not the first load, refetch the orders
                        if (!firstLoad) {
                          getNumberOfOrders({ variables: variables });
                        }
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={liveOrders}
                          onChange={() => {
                            setLiveOrders(!liveOrders);

                            variables.liveOrders = !liveOrders;

                            //if its not the first load, refetch the orders
                            if (!firstLoad) {
                              getNumberOfOrders({ variables: variables });
                            }
                          }}
                          value="liveOrders"
                          color="secondary"
                        />
                      }
                      label="Live Orders"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={completedOrders}
                          onChange={() => {
                            setCompletedOrders(!completedOrders);

                            variables.completedOrders = !completedOrders;

                            //if its not the first load, refetch the orders
                            if (!firstLoad) {
                              getNumberOfOrders({ variables: variables });
                            }
                          }}
                          value="completedOrders"
                          color="secondary"
                        />
                      }
                      label="Completed Orders"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cancelledOrders}
                          onChange={() => {
                            setCancelledOrders(!cancelledOrders);

                            variables.cancelledOrders = !cancelledOrders;

                            //if its not the first load, refetch the orders
                            if (!firstLoad) {
                              getNumberOfOrders({ variables: variables });
                            }
                          }}
                          value="cancelledOrders"
                          color="secondary"
                        />
                      }
                      label="Cancelled Orders"
                    />
                  </FormGroup>
                </div>
              </Popover>
            </Fragment>
          }
        />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ paddingBottom: "0px" }}>
              <Typography variant="subtitle2" style={{ color: "grey" }}>
                Search for orders
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Order Number"
                value={orderNumber}
                fullWidth
                type="number"
                disabled={advancedSearchVisability}
                onChange={(e) => {
                  setOrderNumber(e.target.value);

                  variables.orderNumber = e.target.value;
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="First Name"
                value={firstName}
                fullWidth
                disabled={advancedSearchVisability}
                onChange={(e) => {
                  setFirstName(e.target.value);

                  variables.firstName = e.target.value;
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="Last Name"
                value={lastName}
                fullWidth
                disabled={advancedSearchVisability}
                onChange={(e) => {
                  setLastName(e.target.value);

                  variables.lastName = e.target.value;
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label="Postcode"
                value={postcode}
                fullWidth
                disabled={advancedSearchVisability}
                onChange={(e) => {
                  setPostcode(e.target.value);

                  variables.postcode = e.target.value;
                }}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={
                  !orderNumber.trim() &&
                  !firstName.trim() &&
                  !lastName.trim() &&
                  !postcode.trim() &&
                  !advancedSearch.trim()
                }
                type="submit"
              >
                <Search style={{ marginRight: "10px" }} />
                Search
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ paddingTop: "0", paddingBottom: "0px" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{ marginBottom: "8px" }}
                  onClick={() => {
                    setAdvancedSearchVisability(!advancedSearchVisability);

                    setAdvancedSearch("");
                  }}
                >
                  Advanced Search
                  {advancedSearchVisability ? (
                    <KeyboardArrowDown
                      style={{
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    <ChevronRight style={{ marginRight: "10px" }} />
                  )}
                </Button>
              </div>
              {advancedSearchVisability && (
                <TextField
                  placeholder="Search All Fields"
                  value={advancedSearch}
                  fullWidth
                  onChange={(e) => {
                    setAdvancedSearch(e.target.value);

                    variables.advancedSearch = e.target.value;
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} style={{ padding: "0" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "275px",
                }}
              >
                {view}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </form>
  );
}
