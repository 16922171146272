import React, { useContext } from "react";
import {
  CashDeposit,
  OneOffPayment,
  ScheduledPayment,
  CardValidation,
  CardDeposit,
  Retry,
  Refund,
} from "../../../assets/images/Icons";
import moment from "moment";
import { Mutation } from "@apollo/react-components";
import { TAKE_DEPOSIT_NOW } from "../../../helpers/queries/Payment";
import { openSnackbar } from "../../../components/reusable/Notifier";
import {
  Typography,
  Grid,
  Divider,
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  Loading,
  ErrorMessage,
  Button,
} from "@web-applications/daffodil-component-library";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { useModal } from "react-modal-hook";
import gql from "graphql-tag";
import Icon from "@mdi/react";
import { mdiCurrencyGbp, mdiCreditCardRefundOutline } from "@mdi/js";
import MUIDataTable from "mui-datatables";
import { orange, lightGreen } from "@material-ui/core/colors";
import { CalendarToday, Clear, CreditCard, Redo } from "@material-ui/icons";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";

import { UserContext } from "../../../components/util/PageWrapper";
import { openModal, closeModal } from "../../../components/reusable/Popup";
import RefundsModal from "./Popups/AddRefund/RefundsModal";
import Modal from "../../../newComponents/Modal";
import UpdateCardDetailsModal from "./Popups/UpdateCardDetailsModal";
import TakePaymentModal from "./Popups/TakePaymentModal";
import RestructurePaymentSchedule from "./Popups/RestructurePaymentSchedule";

const secondary = lightGreen[500];
const primary = orange[500];

const columnWidthTheme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 100,
          padding: "4px 24px",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "13px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "0px",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: "0px",
      },
    },
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: "auto",
      },
      responsiveScrollFullHeight: {
        zIndex: 1,
      },
    },
    MUIDataTablePagination: {
      root: {
        borderBottom: "0",
      },
    },
  },
});

const RETRY_PAYMENT = gql`
  mutation ($paymentId: ID!) {
    retryPayment(paymentId: $paymentId) {
      _id
      payment_number
      time
      timezone
      approval_code
      status
      invoice_number
      amount
      transaction_id
      reference_number
      payment_type
      card_number
      refunded
      cancelled_due_to_restructure
      customer_paid
    }
  }
`;

export const GET_PAYMENTS = gql`
  query getPayments($orderId: ID!) {
    getPayments(orderId: $orderId) {
      _id
      payment_number
      time
      timezone
      approval_code
      status
      invoice_number
      amount
      transaction_id
      reference_number
      payment_type
      card_number
      refunded
      cancelled_due_to_restructure
      customer_paid
    }
  }
`;

const GET_ORDER = gql`
  query getOrder($invoiceNumber: Int) {
    getOrder(invoiceNumber: $invoiceNumber) {
      _id
      invoice_number
      order_status
      total_paid
      cancellation {
        _id
      }
      to_pay
      deposit
      deposit_paid
    }
  }
`;

const CANCEL_PAYMENT = gql`
  mutation cancelPayment($paymentId: ID!, $orderId: ID!) {
    cancelPayment(paymentId: $paymentId, orderId: $orderId) {
      _id
    }
  }
`;
const TOGGLE_REFUNDED_PAYMENT = gql`
  mutation toggleRefundedPayment($paymentId: ID!, $orderId: ID!) {
    toggleRefundedPayment(paymentId: $paymentId, orderId: $orderId) {
      _id
    }
  }
`;
const TOGGLE_CUSTOMER_PAID_PAYMENT = gql`
  mutation toggleCustomerPaid($paymentId: ID!, $orderId: ID!) {
    toggleCustomerPaid(paymentId: $paymentId, orderId: $orderId) {
      _id
    }
  }
`;

export default function PaymentsTable() {
  const { invoiceNumber } = useParams();

  const user = useContext(UserContext);

  const [retryPayment, { loading: retryPaymentLoading }] = useMutation(
    RETRY_PAYMENT,
    {
      onError: () => {
        openSnackbar({
          message:
            "Failed to take retry payment, please refresh the page and try again",
          type: "error",
        });
      },
      onCompleted: () => {
        closeModal();
        openSnackbar({
          message: "Retry successful",
          type: "success",
        });
      },
    }
  );

  function formatData(payments) {
    const tableDataArray = [];

    // remove payments that don't have an invoice Number

    payments = payments
      .filter((payment) => payment.invoice_number !== null)
      .sort((a, b) => new Date(a.time) - new Date(b.time));

    //calculate total paid

    const approvedPayments = payments.filter(
      (payment) =>
        payment.payment_type !== "Refund" &&
        (payment.status === "APPROVED" || payment.status === "COLLECTED") &&
        !payment.refunded
    );

    const allApporvedPaymentTotal = payments
      .filter(
        (payment) =>
          payment.payment_type !== "Refund" &&
          (payment.status === "APPROVED" || payment.status === "COLLECTED")
      )
      .map((succesfulPayment) => parseFloat(succesfulPayment.amount))
      .reduce((a, b) => a + b, 0);

    const allApprovedRefundsTotal = payments
      .filter(
        (payment) =>
          payment.payment_type === "Refund" && payment.status === "APPROVED"
      )
      .map((succesfulPayment) => parseFloat(succesfulPayment.amount))
      .reduce((a, b) => a + b, 0);

    const totalPaid = allApporvedPaymentTotal - allApprovedRefundsTotal;

    let approvedPaymentNumbers = approvedPayments.filter(
      (payment) => !!payment.payment_number
    );

    approvedPaymentNumbers = approvedPaymentNumbers.map((payment) => {
      return payment.payment_number;
    });

    //calculate failed scheduled payments

    const failedPayments = payments.filter(
      (payment) =>
        payment.payment_type === "Scheduled Payment" &&
        payment.status === "FAILED" &&
        !payment.cancelled_due_to_restructure
    );

    const futurePayments = payments.filter(
      (payment) =>
        payment.payment_type === "Scheduled Payment" &&
        (payment.status === "PENDING" || payment.status === "ON HOLD")
    );

    const futurePaymentsNumbers = futurePayments.map(
      (payment) => payment.payment_number
    );

    const arrearsPayments = failedPayments.filter(
      (payment) =>
        !approvedPaymentNumbers.includes(payment.payment_number) &&
        !futurePaymentsNumbers.includes(payment.payment_number)
    );

    const arrearsPaymentsAmounts = arrearsPayments.map((payment) =>
      parseFloat(payment.amount)
    );

    let totalArrears = arrearsPaymentsAmounts.reduce((a, b) => a + b, 0);

    //remove one off payments from this number

    const successfulOneOffPayments = payments.filter(
      (payment) =>
        payment.payment_type === "One-Off Payment" &&
        payment.status === "APPROVED"
    );

    const oneOffAmounts = successfulOneOffPayments.map((payment) =>
      parseFloat(payment.amount)
    );

    if (successfulOneOffPayments.length) {
      const totalOneOff = oneOffAmounts.reduce((total, num) => {
        return total + num;
      });

      totalArrears -= totalOneOff;

      if (totalArrears < 0) {
        totalArrears = 0;
      }
    }

    //calculate pending payments left

    const outstandingPayments = payments.filter(
      (payment) =>
        (payment.status === "PENDING" || payment.status === "ON HOLD") &&
        payment.payment_type !== "One-Off Payment" &&
        payment.payment_type !== "Manual Retry"
    );

    const outstandingPaymentsAmounts = outstandingPayments.map((payment) =>
      parseFloat(payment.amount)
    );

    let totalOutstanding = 0;

    if (outstandingPaymentsAmounts.length) {
      totalOutstanding = outstandingPaymentsAmounts.reduce((total, num) => {
        return total + num;
      });
    }

    payments.forEach((payment) => {
      //Figure out icon

      //get colour
      let colour;

      switch (payment.status) {
        case "COLLECTED":
        case "APPROVED":
          colour = "#8BC34A";
          if (payment.refunded) {
            colour = "orange";
          }
          break;

        case "FAILED":
          colour = "red";
          if (approvedPaymentNumbers.includes(payment.payment_number)) {
            colour = "orange";
          }
          break;

        case "FRAUD":
        case "CANCELLED":
          colour = "red";
          break;

        case "PENDING":
        case "ON HOLD":
          colour = "#20409A";
          break;

        default:
          break;
      }

      let icon;

      switch (payment.payment_type) {
        case "Cash Deposit":
          icon = (
            <CashDeposit
              colour={colour}
              style={{
                marginRight: "10px",
              }}
            />
          );

          break;

        case "Card Deposit":
          icon = (
            <CardDeposit
              colour={colour}
              style={{
                marginRight: "10px",
              }}
            />
          );

          break;

        case "One-Off Payment":
          icon = (
            <OneOffPayment
              colour={colour}
              style={{
                marginRight: "10px",
              }}
            />
          );

          break;

        case "Scheduled Payment":
          icon = (
            <ScheduledPayment
              colour={colour}
              style={{
                marginRight: "10px",
              }}
            />
          );

          break;

        case "Card Validation":
          icon = (
            <CardValidation
              colour={colour}
              style={{
                marginRight: "10px",
              }}
            />
          );

          break;

        case "Automatic Retry":
          icon = (
            <Retry
              colour={colour}
              style={{
                marginRight: "10px",
              }}
            />
          );

          break;

        case "Manual Retry":
          icon = (
            <Retry
              colour={colour}
              style={{
                marginRight: "10px",
              }}
            />
          );

          break;

        case "Refund":
          icon = (
            <Refund
              colour={colour}
              style={{
                marginRight: "10px",
              }}
            />
          );

          break;

        default:
          break;
      }

      //calculate action for row

      let action = [];

      if (user.role_id > 4) {
        action.push(
          <Mutation
            mutation={TOGGLE_REFUNDED_PAYMENT}
            refetchQueries={[
              {
                query: GET_PAYMENTS,

                variables: {
                  orderId: order?._id,
                },
              },
            ]}
          >
            {(toggleRefundedPayment, { loading }) => (
              <Tooltip title="Toggle Refunded" placement="top">
                <FormControlLabel
                  control={<Checkbox checked={payment.refunded} />}
                  // label="Refunded"
                  onClick={() => {
                    openModal({
                      content: (
                        <Card>
                          <CardHeader
                            action={
                              <IconButton
                                aria-label="Close Pop-up"
                                onClick={closeModal}
                              >
                                <Clear />
                              </IconButton>
                            }
                            title="Toggle Refunded"
                          />
                          <CardContent>
                            <Typography variant="body1" gutterBottom>
                              {`This will toggle the refunded. Please confirm that you would like to continue with this.`}
                            </Typography>
                          </CardContent>
                          <CardActions
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button colour="#f7941d" onClick={closeModal}>
                              Cancel
                            </Button>

                            <Button
                              colour="#8BC34A"
                              loading={loading}
                              onClick={() => {
                                closeModal();

                                toggleRefundedPayment({
                                  variables: {
                                    paymentId: payment._id,
                                    orderId: order?._id,
                                  },
                                })
                                  .then(() => {
                                    openSnackbar({
                                      message: "Refunded toggled",
                                      type: "success",
                                    });
                                  })
                                  .catch(() => {
                                    openSnackbar({
                                      message: "Failed to toggle refunded",
                                      type: "error",
                                    });
                                  });
                              }}
                            >
                              Continue
                            </Button>
                          </CardActions>
                        </Card>
                      ),
                    });
                  }}
                >
                  <Clear />
                </FormControlLabel>
              </Tooltip>
            )}
          </Mutation>
        );
      }
      if (user.role_id > 4) {
        action.push(
          <Mutation
            mutation={TOGGLE_CUSTOMER_PAID_PAYMENT}
            refetchQueries={[
              {
                query: GET_PAYMENTS,

                variables: {
                  orderId: order?._id,
                },
              },
            ]}
          >
            {(toggleCustomerPaid, { loading }) => (
              <Tooltip title="Toggle Customer Paid" placement="top">
                <FormControlLabel
                  control={<Checkbox checked={payment.customer_paid} />}
                  // label="Customer Paid"
                  onClick={() => {
                    openModal({
                      content: (
                        <Card>
                          <CardHeader
                            action={
                              <IconButton
                                aria-label="Close Pop-up"
                                onClick={closeModal}
                              >
                                <Clear />
                              </IconButton>
                            }
                            title="Toggle Customer Paid"
                          />
                          <CardContent>
                            <Typography variant="body1" gutterBottom>
                              {`This will toggle the customer paid. Please confirm that you would like to continue with this.`}
                            </Typography>
                          </CardContent>
                          <CardActions
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button colour="#f7941d" onClick={closeModal}>
                              Cancel
                            </Button>

                            <Button
                              colour="#8BC34A"
                              loading={loading}
                              onClick={() => {
                                closeModal();

                                toggleCustomerPaid({
                                  variables: {
                                    paymentId: payment._id,
                                    orderId: order?._id,
                                  },
                                })
                                  .then(() => {
                                    openSnackbar({
                                      message: "Customer Paid toggled",
                                      type: "success",
                                    });
                                  })
                                  .catch(() => {
                                    openSnackbar({
                                      message: "Failed to toggle customer paid",
                                      type: "error",
                                    });
                                  });
                              }}
                            >
                              Continue
                            </Button>
                          </CardActions>
                        </Card>
                      ),
                    });
                  }}
                >
                  <Clear />
                </FormControlLabel>
              </Tooltip>
            )}
          </Mutation>
        );
      }

      if (
        payment.payment_type === "Scheduled Payment" &&
        (payment.status === "FAILED" || payment.status === "FRAUD") &&
        !approvedPaymentNumbers.includes(payment.payment_number) &&
        parseFloat(payment.amount) <= totalOutstanding
      ) {
        action.push(
          <Tooltip title="Retry Payment" placement="top">
            <IconButton
              aria-label="Edit"
              onClick={() => {
                openModal({
                  content: (
                    <Card>
                      <CardHeader
                        action={
                          <IconButton
                            aria-label="Close Pop-up"
                            onClick={closeModal}
                          >
                            <Clear />
                          </IconButton>
                        }
                        title="Retry Payment"
                      />
                      <CardContent>
                        <Typography variant="body1" gutterBottom>
                          {`This will retry a payment of ${new Intl.NumberFormat(
                            "en-GB",
                            {
                              style: "currency",
                              currency: "GBP",
                            }
                          ).format(payment.amount)} from the card: ${
                            payment.card_number
                          }. Please confirm that you would like to continue with this.`}
                        </Typography>
                      </CardContent>
                      <CardActions
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button colour="#f7941d" onClick={closeModal}>
                          Cancel
                        </Button>

                        <Button
                          colour="#8BC34A"
                          loading={retryPaymentLoading}
                          onClick={() => {
                            retryPayment({
                              variables: {
                                paymentId: payment._id,
                              },
                            });
                          }}
                        >
                          Continue
                        </Button>
                      </CardActions>
                    </Card>
                  ),
                });
              }}
            >
              <Redo fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      } else if (
        payment.payment_type === "Card Deposit" &&
        payment.status === "PENDING"
      ) {
        action.push(
          <Mutation
            mutation={TAKE_DEPOSIT_NOW}
            refetchQueries={[
              {
                query: GET_ORDER,
                variables: {
                  invoice_number: payment.invoice_number,
                },
              },
            ]}
          >
            {(takeDepositNow, { loading }) => (
              <Tooltip title="Take Deposit Now" placement="top">
                <IconButton
                  aria-label="Edit"
                  onClick={() => {
                    openModal({
                      content: (
                        <Card>
                          <CardHeader
                            action={
                              <IconButton
                                aria-label="Close Pop-up"
                                onClick={closeModal}
                              >
                                <Clear />
                              </IconButton>
                            }
                            title="Take Deposit Now"
                          />
                          <CardContent>
                            <Typography variant="body1" gutterBottom>
                              {`This will take a deposit of ${new Intl.NumberFormat(
                                "en-GB",
                                {
                                  style: "currency",
                                  currency: "GBP",
                                }
                              ).format(payment.amount)} from the card: ${
                                payment.card_number
                              }. Please confirm that you would like to continue with this.`}
                            </Typography>
                          </CardContent>
                          <CardActions
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button colour="#f7941d" onClick={closeModal}>
                              Cancel
                            </Button>

                            <Button
                              colour="#8BC34A"
                              loading={loading}
                              onClick={() => {
                                closeModal();
                                takeDepositNow({
                                  variables: {
                                    payment_id: payment._id,
                                    agent_id: user._id,
                                  },
                                })
                                  .then(() => {
                                    openSnackbar({
                                      message: "Deposit successful",
                                      type: "success",
                                    });
                                  })
                                  .catch(() => {
                                    openSnackbar({
                                      message: "Failed to take deposit now",
                                      type: "error",
                                    });
                                  });
                              }}
                            >
                              Continue
                            </Button>
                          </CardActions>
                        </Card>
                      ),
                    });
                  }}
                >
                  <Redo fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Mutation>
        );
      }

      if (user.role_id > 4 && payment.status === "PENDING") {
        action.push(
          <Mutation
            mutation={CANCEL_PAYMENT}
            refetchQueries={[
              {
                query: GET_PAYMENTS,

                variables: {
                  orderId: order?._id,
                },
              },
            ]}
          >
            {(cancelPayment, { loading }) => (
              <Tooltip title="Cancel Payment" placement="top">
                <IconButton
                  aria-label="Close"
                  onClick={() => {
                    openModal({
                      content: (
                        <Card>
                          <CardHeader
                            action={
                              <IconButton
                                aria-label="Close Pop-up"
                                onClick={closeModal}
                              >
                                <Clear />
                              </IconButton>
                            }
                            title="Cancel Payment"
                          />
                          <CardContent>
                            <Typography variant="body1" gutterBottom>
                              {`This will cancel the payment. Please confirm that you would like to continue with this.`}
                            </Typography>
                          </CardContent>
                          <CardActions
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Button colour="#f7941d" onClick={closeModal}>
                              Cancel
                            </Button>

                            <Button
                              colour="#8BC34A"
                              loading={loading}
                              onClick={() => {
                                closeModal();

                                cancelPayment({
                                  variables: {
                                    paymentId: payment._id,
                                    orderId: order?._id,
                                  },
                                })
                                  .then(() => {
                                    openSnackbar({
                                      message: "Payment cancelled",
                                      type: "success",
                                    });
                                  })
                                  .catch(() => {
                                    openSnackbar({
                                      message: "Failed to cancel payment",
                                      type: "error",
                                    });
                                  });
                              }}
                            >
                              Continue
                            </Button>
                          </CardActions>
                        </Card>
                      ),
                    });
                  }}
                >
                  <Clear />
                </IconButton>
              </Tooltip>
            )}
          </Mutation>
        );
      }

      const data = [
        {
          columnDetails: {
            name: "Payment Id",
            options: {
              filter: false,
              sort: false,
              display: "false",
            },
          },
          columnData: payment._id,
        },
        {
          columnDetails: {
            name: "Payment Schedule Number",
            options: {
              filter: false,
              sort: true,
              display: "true",
            },
          },
          columnData: payment.payment_number,
        },
        {
          columnDetails: {
            name: "Timezone",
            options: {
              filter: false,
              sort: false,
              display: "excluded",
            },
          },
          columnData: payment.timezone,
        },
        {
          columnDetails: {
            name: "Time",
            options: {
              filter: false,
              sort: true,
              display: "true",
              customBodyRender: (value) =>
                moment(value).format("DD/MM/YY, HH:mm:ss"),
            },
          },
          columnData: payment.time,
        },
        {
          columnDetails: {
            name: "Payment Type",
            options: {
              filter: false,
              sort: false,
              display: "true",
            },
          },
          columnData: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {icon}
              {`${payment.payment_type}${
                payment.customer_paid ? " - Customer Paid" : ""
              }`}
            </div>
          ),
        },
        {
          columnDetails: {
            name: "Payment Type",
            options: {
              filter: true,
              sort: true,
              display: "excluded",
              download: false,
            },
          },
          columnData: payment.payment_type,
        },
        {
          columnDetails: {
            name: "Status",
            options: {
              filter: true,
              sort: true,
              display: "true",
            },
          },
          columnData: payment.status,
        },
        {
          columnDetails: {
            name: "Approval Code",
            options: {
              filter: false,
              sort: true,
              display: "true",
            },
          },
          columnData: payment.approval_code,
        },
        {
          columnDetails: {
            name: "Order Number",
            options: {
              filter: false,
              sort: true,
              display: "true",
            },
          },
          columnData: payment.invoice_number,
        },
        {
          columnDetails: {
            name: "Amount",
            options: {
              filter: false,
              sort: true,
              display: "true",
            },
          },
          columnData: new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
          }).format(payment.amount),
        },
        {
          columnDetails: {
            name: "Refunded",
            options: {
              filter: true,
              sort: true,
              display: "false",
            },
          },
          columnData: payment.refunded ? "Yes" : "No",
        },
        {
          columnDetails: {
            name: "Transaction ID",
            options: {
              filter: false,
              sort: true,
              display: "false",
            },
          },
          columnData: payment.transaction_id,
        },
        {
          columnDetails: {
            name: "Reference Number",
            options: {
              filter: false,
              sort: true,
              display: "false",
            },
          },
          columnData: payment.reference_number,
        },
        {
          columnDetails: {
            name: "Card Number",
            options: {
              filter: true,
              sort: true,
              display: "false",
            },
          },
          columnData: payment.card_number,
        },
        {
          columnDetails: {
            name: "Actions",
            options: {
              filter: false,
              sort: false,
              display: true,
              download: false,
            },
          },
          columnData: action,
        },
      ];

      tableDataArray.push(data);
    });

    const columnsArray = [];
    const paymentData = [];

    tableDataArray.forEach((columns, index) => {
      const columnData = [];

      columns.forEach((column) => {
        columnData.push(column.columnData);
      });

      paymentData.push(columnData);

      //only create column titles on first pass
      if (index === 0) {
        columns.forEach((column) => {
          columnsArray.push(column.columnDetails);
        });
      }
    });

    return {
      paymentData: paymentData,
      totalPaid: totalPaid,
      totalArrears: totalArrears,
      totalOutstanding: totalOutstanding,
      columnsArray: columnsArray,
      outstandingPayments: outstandingPayments,
    };
  }

  const [showManualRefundModal, hideManualRefundModal] = useModal(() => (
    <Modal>
      <RefundsModal user={user} hideModal={hideManualRefundModal} />
    </Modal>
  ));

  const [showUpdateCardDetailModal, hideUpdateCardDetailModal] = useModal(
    () => (
      <Modal>
        <UpdateCardDetailsModal hideModal={hideUpdateCardDetailModal} />
      </Modal>
    )
  );

  const [showTakePaymentModal, hideTakePaymentModal] = useModal(() => (
    <Modal>
      <TakePaymentModal hideModal={hideTakePaymentModal} />
    </Modal>
  ));

  const [showRestructureOrderModal, hideRestructureOrderModal] = useModal(
    () => (
      <Modal>
        <RestructurePaymentSchedule hideModal={hideRestructureOrderModal} />
      </Modal>
    )
  );

  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: { invoiceNumber: parseInt(invoiceNumber) },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: paymentsData,
    loading: paymentsLoading,
    error: paymentsError,
  } = useQuery(GET_PAYMENTS, {
    variables: {
      orderId: data?.getOrder?._id,
    },
    fetchPolicy: "network-only",
    skip: !data?.getOrder?._id,
  });

  // get payments

  if (loading || paymentsLoading) return <Loading />;

  if (error || paymentsError)
    return <ErrorMessage error={error || paymentsError} />;

  const { getOrder: order } = data;

  //calculate to pay value

  let toPay = order.deposit_paid
    ? parseFloat(order.to_pay)
    : parseFloat(order.to_pay) + parseFloat(order.deposit);

  //format data for payments
  const formattedData = formatData(paymentsData.getPayments);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h5" gutterBottom>
            Payments
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} md={3}>
          {!!order.cancellation ? (
            <Tooltip
              title="A cancellation has been requested, this order cannot be restructured until the cancellation is confirmed/denied"
              placement="top"
              style={{
                marginLeft: "auto",
                marginBottom: "10px",
              }}
            >
              <div>
                <Button fullwidth disabled icon={<CalendarToday />}>
                  Restructure
                </Button>
              </div>
            </Tooltip>
          ) : (
            <Button
              fullwidth
              disabled={order.order_status === "Cancelled"}
              icon={<CalendarToday />}
              onClick={showRestructureOrderModal}
            >
              Restructure
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            fullwidth
            disabled={user.role_id === 1 || order.order_status === "Cancelled"}
            onClick={showUpdateCardDetailModal}
            icon={<CreditCard />}
          >
            Update Card
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            onClick={showManualRefundModal}
            disabled={user.role_id === 1}
            fullwidth
            icon={<Icon path={mdiCreditCardRefundOutline} size={1} />}
          >
            Add Refund
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            onClick={showTakePaymentModal}
            disabled={user.role_id === 1 || order.order_status === "Cancelled"}
            fullwidth
            icon={<Icon path={mdiCurrencyGbp} size={1} />}
          >
            Take Payment
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled
            value={order.total_paid.toFixed(2)}
            label="Total Paid"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled
            value={toPay.toFixed(2)}
            label="Total Outstanding"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled
            value={formattedData.totalArrears.toFixed(2)}
            label="Total Arrears"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">£</InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            disabled
            value={formattedData.outstandingPayments.length}
            label="Payments till Completion"
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          {!formattedData?.paymentData?.length ? (
            <Typography variant="body1">
              There are no payments to display
            </Typography>
          ) : (
            <MuiThemeProvider theme={columnWidthTheme}>
              <MUIDataTable
                title=""
                data={formattedData.paymentData}
                columns={formattedData.columnsArray}
                options={{
                  filterType: "checkbox",
                  selectableRows: "none",
                  print: false,
                  download: false,
                  responsive: "standard",
                  elevation: 0,
                  rowsPerPage: 100,
                }}
              />
            </MuiThemeProvider>
          )}
        </Grid>
      </Grid>
    </>
  );
}
