import addMonths from "date-fns/addMonths";
import getDaysInMonth from "date-fns/getDaysInMonth";

//this function will return the payment date
//adjusted to accomodate shorter months.
//if the payment date is the 31st, and the next month only has 30 days,
//the payment will be taken on the 30th.
//if the origional payment date can be accomodated, it will allways be used.

// ****this cannot be modified without modifying the server side code. calculatePaymentSchedule.ts****

export const calculateSafeDayOfMonth = (paymentDate, origionalPaymentDay) => {
  const firstOfCurrrentMonth = new Date(
    paymentDate.getFullYear(),
    paymentDate.getMonth(),
    1
  );
  //get the first of next month
  const firstOfNextMonth = addMonths(firstOfCurrrentMonth, 1);
  // console.log(`firstOfNextMonth, ${firstOfNextMonth}`);
  //get the total days in next month
  const daysInNextMonth = getDaysInMonth(firstOfNextMonth);
  // console.log(`daysInNextMonth, ${daysInNextMonth}`);

  //allways returns the lowest value. Will adjust date only when required.
  const paymentDay = Math.min(origionalPaymentDay, daysInNextMonth);
  // console.log(`paymentDay, ${paymentDay}`);
  //recreate the date in the "next month".
  paymentDate = new Date(
    firstOfNextMonth.getFullYear(),
    firstOfNextMonth.getMonth(),
    paymentDay
  );
  // console.log(`paymentDate, ${paymentDate}`);
  return paymentDate;
};
