import addWeeks from "date-fns/addWeeks";
import format from "date-fns/format";
import { calculateSafeDayOfMonth } from "./calculateSafeDayOfMonth";
// this logic is an adaptation of the server side code with the same name.
//Payment schedules should only ever be calculated with one of these two utils
//DO NOT CHANGE ONE WITHOUT CHANGING THE OTHER

export default function calculatePaymentSchedule(parkedOrder) {
  const schedule = [];

  let remainingToPay = parseInt(parkedOrder.to_pay);
  console.log(parkedOrder);
  //   set the payment date, use today if no date is set.
  let paymentDate = parkedOrder.first_payment_date
    ? new Date(Number(parkedOrder.first_payment_date))
    : new Date();

  console.log(paymentDate);

  //   set the deposit date, use today if no date is set.
  let depositDate = parkedOrder.deposit_date
    ? new Date(Number(parkedOrder.deposit_date))
    : new Date();

  const weeklyPayment = parseInt(parkedOrder.weekly_payment);
  const origionalPaymentDay = paymentDate.getDate();
  const orderType = parkedOrder.order_type;
  //get the day the payment should be taken on.

  let paymentNumber = 1;

  //add the deposit
  if (orderType !== "cashOnly") {
    schedule.push(
      `${format(depositDate, "dd/MM/yyyy")} - £${Number(
        parkedOrder.deposit
      ).toFixed(2)} (Deposit)`
    );
  }

  while (remainingToPay >= weeklyPayment) {
    console.log(
      `Creating Payment ${paymentNumber} for order ${parkedOrder.order_id}`
    );
    schedule.push(
      `${format(paymentDate, "dd/MM/yyyy")} - £${weeklyPayment.toFixed(2)}`
    );
    switch (parkedOrder.order_type) {
      case "weekly":
        paymentDate = addWeeks(paymentDate, 1);
        break;
      case "fortnightly":
        paymentDate = addWeeks(paymentDate, 2);
        break;
      case "4weekly":
        paymentDate = addWeeks(paymentDate, 4);
        break;
      case "monthly":
        //are there enough days in the next month to accomodate this payment date?
        //find the start of the months, to make sure we never skip a month when using addMonth()
        paymentDate = calculateSafeDayOfMonth(paymentDate, origionalPaymentDay);
        break;
      default:
        console.log(
          `${new Date().toISOString} 68 order type is not valid ${
            parkedOrder.order_type
          }`
        );
        throw new Error(
          `parkedOrder.order_type is not valid: ${parkedOrder.order_type}`
        );
    }
    paymentNumber += 1;

    remainingToPay -= weeklyPayment;
  }

  //add the final payment
  if (remainingToPay > 0) {
    schedule.push(
      `${format(paymentDate, "dd/MM/yyyy")} - £${remainingToPay.toFixed(2)}`
    );
  }
  console.log("schedule", schedule);
  return schedule;
}
