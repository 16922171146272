import React, { useContext } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import gql from "graphql-tag";
import { isMobileOnly } from "react-device-detect";
import Icon from "@mdi/react";
import { mdiClose, mdiPlus, mdiCloudDownload } from "@mdi/js";
import { Form } from "react-final-form";
import { GET_UI_LIST_ENTRIES } from "../../../helpers/queries/CustomerVisitOutcome";

import Card from "./../../../newComponents/Card";
import Typography from "./../../../newComponents/Typography";
import Spacer from "./../../../newComponents/Spacer";
import Button from "./../../../newComponents/Button";
import IconButton from "./../../../newComponents/IconButton";
import Select from "./../../../newComponents/Select";
import FlexBox from "../../../newComponents/FlexBox";

import { openModal, closeModal } from "./../../reusable/Popup";
import { openSnackbar } from "../../reusable/Notifier";

import { UserContext } from "./../../util/PageWrapper";
import DownloadPaymentsModal from "./DownloadPaymentsModal";
import DebtReportModal from "./DebtReportModal";
import DebtorsReportModal from "./DebtorsReportModal";
import { useNewParkedOrderHook } from "../../reusable/useNewParkedOrderHook";

// const NEW_PARKED_ORDER = gql`
//   mutation newParkedOrder($storeId: String!) {
//     newParkedOrder(storeId: $storeId) {
//       _id
//     }
//   }
// `;

const GET_SCHEDULED_PAYMENTS_DOWNLOAD = gql`
  query getMorningPaymentsDownloadUrl {
    getMorningPaymentsDownloadUrl
  }
`;

//removed to unify parked order creation.
// const [newParkedOrder, { loading }] = useMutation(NEW_PARKED_ORDER, {
//   onCompleted: ({ newParkedOrder }) => {
//     closeModal();
//     history.push(`/new-order/choose-customer/${newParkedOrder._id}`);
//   },
//   onError: (error) => {
//     openSnackbar({
//       message: `Something went wrong ${error.message}.`,
//       type: "error",
//     });
//   },
// });

export default function Home({ history }) {
  const [newParkedOrder, { loading }] = useNewParkedOrderHook(history);
  const [
    getMorningPaymentsDownloadUrl,
    { loading: downloadMorningPaymentsLoading },
  ] = useLazyQuery(GET_SCHEDULED_PAYMENTS_DOWNLOAD, {
    onError: () => {
      openSnackbar({
        message: "Failed to get download, please try again.",
        type: "error",
      });
    },
    onCompleted: ({ getMorningPaymentsDownloadUrl }) => {
      window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${getMorningPaymentsDownloadUrl}`;
      closeModal();
    },
  });

  const { data: paymentsDownloadAllowed } = useQuery(GET_UI_LIST_ENTRIES, {
    variables: { type: "paymentsdownloadallowed" },
  });

  const user = useContext(UserContext);

  // console.log("user", user);

  // calculate if user can see the payment download button

  // const paymentsDownloadAllowed =
  //   user.email === "support@daffodil-marketing.com" ||
  //   user.email === "hollie.pearson@daffodil-marketing.com" ||
  //   user.email === "davidgreenwood@unitedcarpets.net" ||
  //   user.email === "joelknight@payperweekcarpetsandbeds.co.uk" ||
  //   user.email === "chrisfairclough@payperweekcarpetsandbeds.co.uk" ||
  //   user.email === "matthewdillon@payperweekcarpetsandbeds.co.uk" ||
  //   user.email === "sophiemarriott@payperweekcarpetsandbeds.co.uk" ||
  //   user.email === "rebeccagreen@payperweekcarpetsandbeds.co.uk" ||
  //   user.email === "creditcontrol@payperweek.co.uk" ||
  //   user.email === "jacksmith@payperweek.co.uk" ||
  //   user.email === "alexhallatt@payperweek.co.uk";

  if (isMobileOnly && !sessionStorage.getItem("mobileWarningAccepted")) {
    openModal({
      content: (
        <Card>
          <Typography variant="h1">Mobile Compatibility Warning</Typography>
          <Spacer height="16px" />
          <Typography variant="bodySmall">
            It looks like you're using a mobile device, this system is designed
            for use on tablets or desktop. We recommened switiching to an
            alternative device. Please be aware that continuing on mobile may
            impact the performance and your experience of the system.
          </Typography>
          <Spacer height="16px" />
          <Button
            colour="#F59300"
            onClick={() => {
              sessionStorage.setItem("mobileWarningAccepted", true);
              closeModal();
            }}
          >
            Continue
          </Button>
        </Card>
      ),
    });
  }

  return (
    <>
      <Typography variant="h1">
        {`Welcome ${user.first_name} ${user.last_name}`}
      </Typography>
      <Spacer height="32px" />
      <Button
        colour="#8bc34a"
        icon={
          <Icon path={mdiPlus} title="New Order" size="24px" color="black" />
        }
        onClick={() => {
          if (user.stores.length > 1) {
            // get list of all users stores and format for select box

            const stores = user.stores.map((store) => ({
              value: store._id,
              label: store.name,
            }));

            // ask user which store their order is for
            openModal({
              content: (
                <Card>
                  <FlexBox justifyContent="space-between">
                    <Typography variant="h2">
                      Which store is this order for?
                    </Typography>
                    <IconButton
                      color="black"
                      onClick={() => {
                        closeModal();
                      }}
                      path={mdiClose}
                      title="Close"
                      size="24px"
                    />
                  </FlexBox>
                  <Spacer height="16px" />
                  <Form
                    onSubmit={({ selctedStore }) => {
                      newParkedOrder({
                        variables: {
                          storeId: selctedStore,
                        },
                      });
                    }}
                    render={({ handleSubmit, pristine }) => (
                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                      >
                        <Select
                          name="selctedStore"
                          label="Store"
                          required
                          options={stores}
                        />
                        <Button
                          colour="#f7941d"
                          disabled={pristine}
                          type="submit"
                          loading={loading}
                        >
                          Continue
                        </Button>
                      </form>
                    )}
                  />
                </Card>
              ),
            });
          } else {
            // order can only be for one store so!
            newParkedOrder({
              variables: {
                storeId: user.stores[0]._id,
              },
            });
          }
        }}
      >
        New Order
      </Button>
      {paymentsDownloadAllowed?.entries
        ?.map((entry) => entry.value)
        .includes(user.email) && (
        <>
          <Spacer height="16px" />
          <Button
            colour="#8bc34a"
            icon={
              <Icon
                path={mdiCloudDownload}
                title="Download Todays Payment Run"
                size="24px"
                color="black"
              />
            }
            loading={downloadMorningPaymentsLoading}
            onClick={() => {
              getMorningPaymentsDownloadUrl();
            }}
          >
            Download Todays Payment Run
          </Button>
          <Spacer height="16px" />
          <Button
            colour="#8bc34a"
            icon={
              <Icon
                path={mdiCloudDownload}
                title="Download Payment File"
                size="24px"
                color="black"
              />
            }
            onClick={() => {
              openModal({
                content: <DownloadPaymentsModal />,
              });
            }}
          >
            Download Payment File
          </Button>
        </>
      )}
      {user.role_id >= 4 && (
        <>
          <Spacer height="16px" />

          <Button
            colour="#8bc34a"
            icon={
              <Icon
                path={mdiCloudDownload}
                title="Debt report"
                size="24px"
                color="black"
              />
            }
            onClick={() => {
              openModal({
                content: <DebtReportModal />,
              });
            }}
          >
            Debt report
          </Button>
          <Spacer height="16px" />

          <Button
            colour="#8bc34a"
            icon={
              <Icon
                path={mdiCloudDownload}
                title="Debtors report"
                size="24px"
                color="black"
              />
            }
            onClick={() => {
              openModal({
                content: <DebtorsReportModal />,
              });
            }}
          >
            Debtors report
          </Button>
        </>
      )}
    </>
  );
}
