import React from "react";

import { Snackbar, SnackbarContent, Typography } from "@material-ui/core";

import { CheckCircle, Error } from "@material-ui/icons";

let openSnackbarFn;

class Notifier extends React.Component {
  state = {
    open: false,
    message: "",
    type: "",
    link: null,
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = ({ message, type, link }) => {
    this.setState({
      open: true,
      message,
      type,
      link,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: "",
      type: "",
    });
  };

  renderLink = () => {
    const { link } = this.state;
    if (!link) {
      return null;
    }
    return (
      <a
        href={link[0]}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "underline", color: "#ffffff" }}
      >
        {link[1]}
      </a>
    );
  };

  render() {
    let color;

    if (this.state.type === "success") {
      color = "green";
    } else if (this.state.type === "error") {
      color = "red";
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={this.state.open}
        autoHideDuration={4000}
        onClose={this.handleSnackbarClose}
      >
        <SnackbarContent
          aria-describedby="Notifications"
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              {this.state.type === "success" && <CheckCircle />}
              {this.state.type === "error" && <Error />}
              <Typography
                variant="body1"
                style={{ color: "white", marginLeft: "15px" }}
              >
                {this.state.message}
                <br></br>
                {this.renderLink()}
              </Typography>
            </span>
          }
          style={{ backgroundColor: color }}
        />
      </Snackbar>
    );
  }
}

export function openSnackbar({ message, type, link }) {
  openSnackbarFn({ message, type, link });
}

export default Notifier;
